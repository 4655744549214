/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import 'react-toastify/dist/ReactToastify.css'
import { KTSVG } from '../../_metronic/helpers'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { Button } from 'react-bootstrap-v5'
import Spinner from 'react-bootstrap/Spinner';
import { Tab, Tabs } from 'react-bootstrap-v5'
import MrrListModal from '../components/MrrListModal'
import FinishedGoodsDeliveryModal from '../components/FinishedGoodsDeliveryModal'

const ProductionOrderDetails = (props) => {
	const [details, setDetails] = useState('')
	const [approveLoading, setApproveLoading] = useState(false)
	const [stockOutDetailsId, setStockOutDetailsId] = useState(null)
	const [mrrListModalShow, setMrrListModalShow] = useState(false)
	const [finishedGoodsDeliveryModalShow, setFinishedGoodsDeliveryModalShow] = useState(false)

	useEffect(() => {
		getProductionOrderDetails(props.match.params.id)
	}, [])

	const getProductionOrderDetails = async (pId) => {
		fetch(`${process.env.REACT_APP_API_URL}production_order_details/${pId}`, getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setDetails(resp.data)
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}




	const approve = async () => {

		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Approve it!',
		}).then((result) => {
			if (result.value) {
				setApproveLoading(true);
				const formData = {
					orderId: props.match.params.id
				}
				const requestOptions = postRequestOptions(formData)

				fetch(process.env.REACT_APP_API_URL + 'production_order_approve', requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							const successMsg = resp.successMessage
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: successMsg,
							})
							getProductionOrderDetails(props.match.params.id)
							setApproveLoading(false);
						} else {
							const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: errorsMessage,
							})
							setApproveLoading(false);
						}
					})
					.catch((error) => {
						console.log(error, 'catch the hoop')
						setApproveLoading(false);
					})
			}
		})
	}

	const objPsum = (items, prop) => {
		return items.reduce(function (a, b) {
			return Number(a) + Number(b[prop]);
		}, 0);
	}

	const handleMrrDetailsShow = (sId) => {
		setStockOutDetailsId(sId);
		setMrrListModalShow(true);
	}

	const mrrListModalClose = () => {
		setMrrListModalShow(false);
	}

	const finishedGoodDeliveryModalClose = () => {
		setFinishedGoodsDeliveryModalShow(false);
	}

	const handleFinishedGoodsDeliveryShow = () => {
		setFinishedGoodsDeliveryModalShow(true);
	}

	const tableBody = () => {
		const tbodies = details.operations?.map((item, index) => {
			const componentValues = Object.values(item.components ?? {})

			let componentsRows = null



			if (componentValues.length === 0) {
				componentsRows = (
					<tr key={`${index}ni`}>
						<td>{index + 1}</td>
						<td> {item.operation.operationName}</td>
						<td>{item.workcenter !== null ? (item.workcenter?.workCenterName) : ''}</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>{item.hasQc}</td>
						<td>{item.duration}</td>
					</tr>
				)
			} else {
				componentsRows = componentValues.map((opi, i) => {
					const slNo = i === 0 ? <td rowSpan={componentValues.length}>{index + 1}</td> : null
					const operationCell =
						i === 0 ? (
							<td rowSpan={componentValues.length}> {item.operation.operationName}</td>
						) : null

					const durationCell =
						i === 0 ? <td rowSpan={componentValues.length}>{item.duration}</td> : null

					const hasQcCell = i === 0 ? <td rowSpan={componentValues.length}>{item.hasQc}</td> : null


					const workCenterCell = i === 0 ? <td rowSpan={componentValues.length}>{item.workcenter !== null ? (item?.workcenter?.workCenterName) : ''}</td> : null


					return (
						<tr key={i}>
							{slNo}
							{operationCell}
							{workCenterCell}
							<td>{opi?.item?.itemName}</td>
							<td>{opi?.item?.itemCode}</td>
							<td>{opi?.warehouse?.warehouseName}</td>
							<td>{(Number(opi.quantity) <= Number(opi.stock)) ? <span className='badge badge-success'>{Number(opi.quantity).toFixed(2)}</span> : <span className='badge badge-danger'>{Number(opi.quantity).toFixed(2)}</span>}</td>
							<td>{Number(opi.stock).toFixed(2)}</td>
							<td>{opi?.item?.unit}</td>
							{hasQcCell}
							{durationCell}
						</tr>
					)
				})
			} // end of else
			return componentsRows
		})

		return tbodies
	}

	return (
		<div className='card card-custom'>
			<div className='card-header'>
				<div className='card-title'>
					<KTSVG
						path='/media/icons/duotune/text/txt001.svg'
						className='svg-icon-1 svg-icon-primary'
					/>
					<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
						Production Order Details
					</span>
				</div>
			</div>
			<div className='card-body'>
				<div className='ml-12'>
					<div className='row'>
						<div className='col-6'>
							<table className='table table-bordered table-hover'>
								<tbody>
									<tr>
										<th>Order No</th>
										<td>{details?.production_order_no}</td>
									</tr>
									<tr>
										<th>Product</th>
										<td>{details?.product?.itemName}</td>
									</tr>
									<tr>
										<th>Unit</th>
										<td>{details?.product?.unit}</td>
									</tr>
									<tr>
										<th>Quantity</th>
										<td>{Number(details.quantity).toFixed(2)}</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div className='col-6'>
							<table className='table table-bordered table-hover'>
								<tbody>
									<tr>
										<th>Scheduled Date</th>
										<td>{details?.scheduledDate}</td>
									</tr>
									<tr>
										<th>Finished Product Warehouse</th>
										<td>{details?.fwarehouse?.warehouseName}</td>
									</tr>
									<tr>
										<th>Remarks</th>
										<td>{details?.remarks}</td>
									</tr>
									<tr>
										<th>Status</th>
										<td>{details?.status}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='form-group row ml-5'>
							<Tabs defaultActiveKey='orderDetails' id='uncontrolled-tab-example' className='mb-3' >
								<Tab eventKey='orderDetails' title='Order Details'>
									<div className='col-12'>
										<div className='table-responsive'>
											<table
												className='table table-bordered table-hover'
												style={{ minWidth: 'max-content' }}
											>
												<thead>
													<tr>
														<th>SL</th>
														<th>Route Stage</th>
														<th>Work Center</th>
														<th style={{ width: '25%' }}>Item</th>
														<th>Code</th>
														<th>Raw Materials Warehouse</th>
														<th>Quantity</th>
														<th>Stock Quantity</th>
														<th>Unit</th>
														<th>Has QC</th>
														<th>Duration(minutes)</th>
													</tr>
												</thead>
												<tbody>{tableBody()}</tbody>
											</table>
										</div>
									</div>
								</Tab>
								<Tab eventKey='costDetails' title='Cost Details'>
									<div className='col-12'>
										<div className='table-responsive'>
											<table
												className='table table-bordered'
												style={{ minWidth: 'max-content', borderColor: "#000" }}
											>
												<thead>
													<tr className='bg-light-dark'>
														<th>SL</th>
														<th>Operations</th>
														<th>Work Center Name</th>
														<th>Est Duration</th>
														<th>Actual Duration</th>
														<th>Cost Per Minutes</th>
														<th>Operation Cost</th>
														<th>WIP Completed Qty</th>
														<th>QC Passed Qty</th>
														<th>QC Damaged Qty</th>
													</tr>
												</thead>
												<tbody>
													{details?.operations?.map((item, index) => {
														return (
															<>
																<tr key={`main-${index}`} className='bg-light-dark'>
																	<td>{index + 1}</td>
																	<td>{item?.operation?.operationName}</td>
																	<td>{item?.workcenter?.workCenterName}</td>
																	<td>{item?.duration}</td>
																	<td>{item?.actDuration}</td>
																	<td>{item?.costPerMin}</td>
																	<td>{item?.operationCost}</td>
																	<td>{item?.wipCompletedQty}</td>
																	<td>{item?.totalPassQc}</td>
																	<td>{item?.totalDamageQc}</td>
																</tr>
																<tr key={`sub-${index}`}>
																	<td colSpan={10}>
																		<table className='table table-bordered table-row-gray-300'>
																			<thead>
																				<tr>
																					<th>SL</th>
																					<th>Item</th>
																					<th>Code</th>
																					<th>Unit</th>
																					<th>Warehouse</th>
																					<th>To Consume</th>
																					<th>Issue Qty</th>
																					<th>Issue Return Qty</th>
																					<th>Consumed</th>
																					<th>Rate</th>
																					<th>Amount</th>
																					<th>MRR/Opening</th>
																					<th>Action</th>
																				</tr>
																			</thead>
																			<tbody>
																				{item.components.map((comp, i) => {
																					return (
																						<tr key={`sub2-${i}`}>
																							<td>{i + 1}</td>
																							<td>{comp?.itemName}</td>
																							<td>{comp?.itemCode}</td>
																							<td>{comp?.unitName}</td>
																							<td>{comp?.warehouseTitle}</td>
																							<td>{Number(comp?.quantity).toFixed(4)}</td>
																							<td>{Number(comp?.issueQty).toFixed(4)}</td>
																							<td>{Number(comp?.issueReturnQty).toFixed(4)}</td>
																							<td>{Number(comp?.consumedQty).toFixed(4)}</td>
																							<td>{Number(comp?.rate).toFixed(4)}</td>
																							<td>{Number(comp?.amount).toFixed(4)}</td>
																							<td>{comp?.mrrInfo || comp?.openingInfo}</td>
																							<td>{comp?.stockOutDetailsId !== null &&
																								<>
																									<button type="button" className="btn btn-primary btn-sm" onClick={() => handleMrrDetailsShow(comp.stockOutDetailsId)}>Details</button>
																								</>
																							}</td>
																						</tr>
																					)
																				})}
																			</tbody>
																			<tfoot>
																				<tr>
																					<td colSpan={10} style={{ textAlign: "right" }}> Total</td>
																					<td>{objPsum(item.components, 'amount').toFixed(4)}</td>
																					<td colSpan={2}></td>
																				</tr>
																			</tfoot>
																		</table>
																	</td>

																</tr>
															</>
														)
													})}
												</tbody>
											</table>
										</div>
									</div>
									{details?.operations?.length > 0 &&
										<div className="col-6">
											<table className="table table-bordered table-hover">
												<tbody>
													{/* <tr>
														<th>Total Operation Cost</th>
														<td>{objPsum(details.operations, 'operationCost').toFixed(4)}</td>
													</tr> */}
													<tr>
														<th>Total Raw Material Cost</th>
														<td>{objPsum(details.operations, 'totalRawMaterialCost').toFixed(4)}</td>
													</tr>

													<tr>
														<th>Total Labor Cost</th>
														<td>{Number(details?.labourCost) .toFixed(4)}</td>
													</tr>
													<tr>
														<th>Total Overhead Cost</th>
														<td>{ (Number(details?.labourCost) + Number(details?.otherOverheadCost)-Number(details?.operationCost)).toFixed(4)}</td>
													</tr>

													<tr>
														<th>Grand Total Production Cost</th>
														<td>{
														(Number(objPsum(details.operations, 'totalRawMaterialCost')) + (Number(details?.labourCost) + Number(details?.otherOverheadCost)+Number(details?.operationCost))).toFixed(4)
														}</td>
													</tr>
												</tbody>
											</table>
										</div>
									}
								</Tab>
							</Tabs>
						</div>

						<div className='row justify-content-center mt-2'>
							{(details?.status === 'created' && details?.hasAccessProductionOrderApprove === 1 ) && (
								<div className='col-4'>
									<div className='d-grid gap-2'>
										<button type="primary" disabled={approveLoading} className="btn btn-sm btn-primary me-2" onClick={() => approve()}>
											{approveLoading &&
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
											}
											<span>Approve</span>
										</button>
									</div>
								</div>
								)
							}
							{(details?.status !== 'completed' && details?.status !== 'created') &&
								<div className='col-4'>
									<div className='d-grid gap-2'>
										{/* <Button variant='info' size='sm'>
										Edit
									</Button> */}
										<Button variant='primary' size='sm' onClick={handleFinishedGoodsDeliveryShow}>
											Finished Goods Delivery
										</Button>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
				<MrrListModal show={mrrListModalShow} handleClose={mrrListModalClose} stockOutDetailsId={stockOutDetailsId} />
				<FinishedGoodsDeliveryModal show={finishedGoodsDeliveryModalShow} handleClose={finishedGoodDeliveryModalClose} idProductionOrder={props.match.params.id} />
			</div>
		</div>
	)
}

export default ProductionOrderDetails
